.usecase {
  position: absolute;
  width: 30%;
  height: 60%;
  right: 10%;
  top: 15%;
}
.left-container{
  position: fixed;
  width: 130%;
  top: 5%;
  left: -.5%;
  max-width: 57.5%;
}

.header {
  width: 100%;
  height: 15%;
  text-align: left;
  color: var(--fontWhite);
  font-weight: 300;
  font-size: 0.8vw;
  font-family: var(--fontFamily1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--bgWhite);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);


}

.main {
  width: 100%;
  height: 85%;
  text-align: left;
  background: var(--bgDark);
  padding: 3%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  margin-bottom: 5%;
  background: var(--bgWhite);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
}

/* .content {
  margin-bottom: 3%;
  border-radius: 6px;
} */

.content-head {
  font-weight: 700;
  font-size: 1vw;
  font-family: var(--fontFamily1);
  color: var(--fontToolbar);
  margin-bottom: 1%;

}

.content-sub {
  font-weight: 400;
  font-size: 0.8vw;
  font-family: var(--fontFamily1);
  color:var(--fontWhite);
  margin-bottom: 4%;
  
}

.arrowContainer {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
  flex-grow: 1;
  gap: 2%
}

.App {
  text-align: center;
  color: var(--fontWhite);
  padding: 10px;
  background: var(--bgWhite);
  /* z-index: 1; */
  position: absolute;
  width:  -webkit-fill-available;
  height: 100%;

}

.box-wrap {
  /* height: 60%; */
	--angle: 0deg;
	--a1: 1;
	--a2: 0.846;
	--a3: 0.6;
	--a4: 0.5805;
	--a5: 0;
	border: 3px solid;
  z-index: 10;
  border-image: linear-gradient(var(--angle),rgba(15, 44, 104, var(--a1)),rgba(29, 63, 133, var(--a2)),
  rgba(183, 206, 255, var(--a3)),
  rgba(20, 46, 100, var(--a4)),
  rgba(0, 0, 0, var(--a5))) 1;
    transform: perspective(75em) rotateY(-1deg);
    animation: 5s rotate linear infinite;
  padding: 3svh 1vw 3svh 2vw;
  /* max-width: 500px; */
  width: 40%;
  background: var(--bgUcStory);
  opacity: var(--UcStoryOpacity);
  text-align: left;
  box-shadow: -3svh 2vw 3svh -1vw #00000066 !important;
  position: fixed;
  top: 5%;
  right: 8%;
  transition: transform 0.3s ease-in-out;
}

.box-wrap.shift-right {
  transform: translateX(+17.4%); /* Adjust the value as needed */
}

.Tour-box-wrap {
	--angle: 0deg;
  --a1: 1;
	--a2: 0.846;
	--a3: 0.6;
	--a4: 0.5805;
	--a5: 0;
	border: 1px solid var(--bgDark);
  /* border-image: linear-gradient(var(--angle),rgba(15, 44, 104, var(--a1)),rgba(29, 63, 133, var(--a2)),
  rgba(183, 206, 255, var(--a3)),
  rgba(20, 46, 100, var(--a4)),
  rgba(0, 0, 0, --a5)) 1;
	transform: perspective(75em) rotateY(-1deg);
	animation: 5s rotate linear infinite; */

  padding: 15px;
  /* max-width: 500px; */
  width: 45%;
  background: var(--bgMedium);
  opacity: 0.9;
  text-align: left;
  font-family: var(--fontFamily1);
  box-shadow: 10px 10px 54px -6px rgba(0,0,0,1);
  position: fixed;
  bottom: 13%;
}
/* .Guided-Tour-box-wrap {
  transition: transform 300ms, opacity 300ms;
  transform: translateY(-10%);
  opacity: 0;
}

.Guided-Tour-box-wrap.active {
  transform: translateY(0);
  opacity: 1;
} */
.Guided-Tour-box-wrap {
	--angle: 0deg;
  --a1: 1;
	--a2: 0.846;
	--a3: 0.6;
	--a4: 0.5805;
	--a5: 0;
	border: 1px solid var(--bgDark);
  /* border-image: linear-gradient(var(--angle),rgba(15, 44, 104, var(--a1)),rgba(29, 63, 133, var(--a2)),
  rgba(183, 206, 255, var(--a3)),
  rgba(20, 46, 100, var(--a4)),
  rgba(0, 0, 0, --a5)) 1;
	transform: perspective(75em) rotateY(-1deg);
	animation: 5s rotate linear infinite; */
  animation: animateGrow center ease .3s;

  padding: .8%;
  /* max-width: 500px; */
  width: 55%;
  background: var(--bgMedium);
  opacity: 0.9;
  text-align: center;
  font-family: var(--fontFamily1);
  box-shadow: 10px 10px 54px -6px rgba(0,0,0,1);
  position: fixed;
  bottom: 7.25%;
  left: 50%;                
  transform: translateX(-50%);
}

@keyframes animateGrow {
  0% {opacity:0; scale:0%;};
  25% {opacity: var(--HotspotHoverOpacity); scale:0%}
  100% {opacity: var(--HotspotHoverOpacity); scale:100%}
}

.grow-animation {
  animation: animateGrow 0.8s ease-in-out;
  animation-delay: 0.5s; /* Delay between animations */
}

@keyframes rotate {
	0% {
			--a5: 1;
			--angle: 0deg;
	}
	33% {
			--a5: 1;
			--angle: 360deg;
	}
	99% {
		--a5: 1;
		--angle: 360deg;
}
	100% {
			--a5: 0;
			--angle: 0deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


.box-buttons-group {
  display: flex;
  justify-content: end;
  margin-right: 1px;
  font-size: 0.833vw;
  gap: 0.4svh;
  align-items: center;
}

.leftSvg {
  width: 8%;
  height: 8%;
  fill: var(--bgUcStoryNextPrevButton);
}
.rightCloseSvg {
  width: 8%;
  height: 8%;
  fill: var(--bgUcStoryNextPrevButton);
}
.rightSvg{
  fill: var(--bgUcStoryNextPrevButton);
}

.box-title {
  color: var(--UcStoryFontColor);
  font-size: var(--UcStoryFontSize);
  font-family: var(--UcStoryFontFamily);
  font-weight: var(--UcStoryFontWeight);
  margin-top: 1svh;
  margin-bottom: 3svh;
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease
}

.Tour-box-title {
  color: var(--fontWhite);
  font-size: var(--UcStoryBlocksFontSize);
  font-weight: 500;
  margin-bottom: 15px;
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease
}
.Guided-Tour-box-title {
  color: var(--fontWhite);
  font-size: var(--UcGTStoryBlocksFontSize);
  font-weight: var(--UcGTStoryBlocksContentFontWeight);
  margin-bottom: 0.8%;
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease
}


.value-box-title {
  color: var(--UcStoryFontColor);
  font-size: var(--UcStoryBlocksFontSize);
  font-family: var(--UcStoryBlocksFontFamily);
  font-weight: var(--UcStoryBlocksFontWeight);
  margin-top: 3%;
  margin-bottom: 2%;
  animation: fadeIn ease 0.2s;
}

.value-line{
  background: var(--bgMedium);
  margin-top: 3%;
  border: 1px solid rgba(48, 61, 87, 1);
}

.box-content {
  display: flex;
  flex-direction: column;
  gap: 1svh;
  /* max-height: calc(100svh - 220px); */
  /* overflow-y: scroll; */
}

.Tour-box-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100svh - 220px);
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease
  /* overflow-y: scroll; */
}

.Guided-Tour-box-content {
  display: flex;
  flex-direction: column;
  gap: .8%;
  max-height: calc(100svh - 220px);
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease
  /* overflow-y: scroll; */
}

.Tour-box-content>div {
  padding: 15px;
  font-size: 0.938vw;
  border-radius: 6px;
  background: var(--bgDark);
}

.Guided-Tour-box-content>div {
  padding: 1%;
  font-size: var(--UcGTStoryBlocksContentFontSize);
  border-radius: 6px;
  background: var(--bgDark);
}

.box-content>div {
  padding: 3.5%;
  font-size: var(--UcStoryBlocksContentFontSize);
  border-radius: 6px;
  background: var(--bgUcStoryBlocks);
}

.box-content > div:last-child {
  margin-bottom: 2%;
}

.current-uc{
  box-shadow: 0 3px 6px rgba(192,192,192, 0.2), /* Larger shadow for depth */
              0 6px 12px rgba(192,192,192, 0.4); /* Smaller shadow for subtle effect */
  border: 2px solid #C0C0C0; /* Optional: border for better visibility */
  }

.box-content-vp>div {
  padding-left: 0%;
  padding-right: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: var(--UcStoryBlocksContentFontSize);
  color: var(--UcStoryBlocksContentFontColor);
  border-radius: 6px;
  background: var(--bgUcStoryBlocks);
}

.content-title {
  font-family: var(--UcStoryBlocksFontFamily);
  color: var(--UcStoryFontColor);
  font-size: var(--UcStoryBlocksFontSize);
  font-weight: var(--UcStoryBlocksFontWeight);
  color: var(--UcStoryBlocksContentFontColor);
  animation: fadeIn ease 0.2s;
  -webkit-animation: fadeIn ease
}

.content-description {
  margin-Top: 1svh;
  color: var(--UcStoryBlocksContentFontColor);
  font-size: var(--UcStoryBlocksContentFontSize);
  font-family: var(--UcStoryBlocksContentFontFamily);
  font-weight: var(--UcStoryBlocksContentFontWeight);
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease;
  line-height: var(--UcStoryBlocksContentLineHeight);
}

.Guided-content-description {
  margin-Top: 1svh;
  color: var(--UcGTStoryBlocksContentFontColor);
  font-size: var(--UcGTStoryBlocksContentFontSize);
  font-family: var(--UcGTStoryBlocksContentFontFamily);
  font-weight: var(--UcGTStoryBlocksContentFontWeight);
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease;
  line-height: var(--UcGTStoryBlocksContentLineHeight);
}

.content-description-vp {
  margin-Top: 0svh;
  color: var(--UcStoryBlocksContentFontColor);
  font-size: var(--UcStoryBlocksContentFontSize);
  font-family: var(--UcStoryBlocksContentFontFamily);
  font-weight: var(--UcStoryBlocksContentFontWeight);
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease;
  line-height: var(--UcStoryBlocksContentLineHeight);
}

.CTA-Container{
position: fixed;
width: 70%;
top: 15%;
} 

.CTA-cross-btn{
position: absolute;
top: 10%;
cursor: pointer;
right: 0;
}

.CTA-Card-Container{
  width: 25%;
  border-radius: 12px;
  border: 2px;
  background: var(--bgMedium);
  opacity: 0.9;
  /* background: linear-gradient(0deg, rgba(3, 10, 27, 0.85), rgba(3, 10, 27, 0.85)),
  linear-gradient(40.07deg, #0F2C68 -3.97%, rgba(29, 63, 133, 0.84603) 5.79%, rgba(183, 206, 255, 0.6) 10.01%, rgba(20, 46, 100, 0.580451) 22.62%, rgba(0, 0, 0, 0) 59.42%);
  ; */
  padding: 2% 1.5% 2% 1.5%;
  position: fixed;
  left: 36%;
  top: 18%;
}


.CTA-ButtonList{
width: 96%;
height: 68px;
border-radius: 6px;
background: var(--bgDark);
font-family: var(--fontFamily1);
font-size: 0.8999999999999999vw;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: var(--fontWhite);
margin: 6px;
padding-left: 24px;
border-radius: 6px;
border: 0px;
}

.CTA-ButtonContainer{
  margin-top: 4%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.cat-app{
width: 100%;
height: 100%;
}
.CTA-Title{
color: var(--fontWhite);
font-size: 1.667vw;
font-weight: 500;
display: flex;
padding-left: 2%;
font-family: var(--fontFamily1);
}

.blink {
width: 8%;
height: 8%;
animation: crescendo 1s alternate infinite ease-in;
}
/* .highlight {
width: 8%;
height: 8%;
animation: highlighter 1s alternate infinite ease-in;
} */

@keyframes crescendo {
0%   {transform: scale(1);}
100% {transform: scale(1.2);}
}
/* @keyframes highlighter {
0%   {transform: font-size(1);}
100% {transform: font-size(1.2);}
} */

::-webkit-scrollbar {
width: 10px;
}

::-webkit-scrollbar-track {
background-color:  rgba(3, 10, 27);;
-webkit-border-radius: 10px;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 10px;
border-radius: 10px;
background: var(--bgWhite); 
}
.animationHover-enter {
  opacity: 0;
  transform: translateY(-10%);
}

.animationHover-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.animationHover-exit {
  opacity: 1;
  transform: translateY(0);
}

.animationHover-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: opacity 300ms, transform 300ms;
}


.player {
    aspect-ratio: 16/9;
    position: absolute;
    width: 46.875%;
    top: 15%;
    left: 25%;
    opacity: 1;
    z-index: 1;
}

.content-shadow-rect {
    /* Rectangle 11 */

    position: absolute;
    width: 43.54%;
    height: 41.57%;
    left: 29.167%;
    top: 34.44%;

    background: var(--bgShadow);
    opacity: 0.7;
    filter: blur(38px);
    border-radius: 12px;
}
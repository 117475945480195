.wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    overflow: hidden;
}

.videoWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}



.bg {
position: fixed;
top: 0;
left:0;
height: 100svh;
/* width: 1vw; */

margin: 0;
overflow: hidden;
object-fit: cover;
pointer-events: none;
filter: none;
z-index: -5;

}

.ec-video {
    filter:none;
}
  

.info-button {
    box-sizing: border-box;

    position: absolute;
    width: 18.125%;
    height: 15.33%;
    /* left: 1249px; offset -> 16 px = 0.833% + width = 18.95833 */
    top: 78.7%;

    background: var(--bgMedium);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    opacity: 0.85;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
}
.info-button1 {
    box-sizing: border-box;

    position: absolute;
    width: 56.0416%;
    height: 15.33%;
    /* left: 1249px; offset -> 16 px = 0.833% + width = 18.95833 */
    top: 60%;

    background: var(--bgMedium);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    opacity: 0.85;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
}
.ioc {
	left: 43.54167%;
}
/* .info-button:hover {
    width: 20.125%;
    height: 17.013%;
} */

.retail {
    left: 62.5%; 
}

.manufacturing {
    left: 43.54167%;
}

.digcities {
    left: 81.45833%;
}

.info-head {
    position: absolute;
    /* width: 5.99%; */
    height: 2.037%;
    /* left: box start + 109px = 5.677%; */
    top: 80.37%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 700;
    font-size: 1.042vw;
    line-height: 2.037svh;

    /* identical to box height, or 110% */

    color: var(--fontWhite);
}

.info-head1 {
    position: absolute;
    /* width: 5.99%; */
    height: 2.037%;
    /* left: box start + 109px = 5.677%; */
    top: 63%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 700;
    font-size: 1.042vw;
    line-height: 2.037svh;

    /* identical to box height, or 110% */

    color: var(--fontWhite);
}

.info-head-edge-city {
    top: 80.37%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 700;
    font-size: 1.042vw;
    line-height: 2.037svh;

    /* identical to box height, or 110% */

    color: var(--fontWhite);
}

.ioc-text {
    left: 56.667%;
}
.manu-text {
    left: 49.21867%;
}

.retail-text {
    left: 68.177%;
}

.dc-text {
    left: 87.13533%;
}

.start-text {
    top: 82.7815%;
    left: 84%;
}

.start-icon {
    left: 79%;
    top: 81.2815%;
}

.start-ex {
    /* height: 13.33%; */
    left: 77%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.button-text{
    user-select: none;
}

.clickable {
    cursor: pointer;
}

.info-sub {
    position: absolute;
    width: 11.615%;
    height: 5.55%;
    /* left: 1358px; */
    top: 83.33%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 400;
    font-size: 0.729vw;
    line-height: 1.852svh;

    /* or 143% */

    color: var(--fontWhite);

    opacity: 0.8;
}
.info-sub1 {
    position: absolute;
    width: 35%;
    height: 5.55%;
    /* left: 1358px; */
    top: 66%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 400;
    font-size: 0.729vw;
    line-height: 1.852svh;

    /* or 143% */

    color: var(--fontWhite);

    opacity: 0.8;
}

.icon {
    position: absolute;
    width: 4.167%;
    aspect-ratio: 1;
    top: 81.277%;
    /* left offset -> 19px = 0.9895833% */
}
.icon1 {
    position: absolute;
    width: 4.167%;
    aspect-ratio: 1;
    top: 63.152%;
    /* left offset -> 19px = 0.9895833% */
}

.manu-icon {
    left: 44.5312533%;
}

.retail-icon {
    left: 63.4895833%;
}

.dc-icon{
    left: 82.4479133%;
}
.ioc-icon{
    left: 50.5%;
}

/* Blur elements */

.blur-rect {
    position: absolute;
    width: 100%;
    height: 35%;
    /* margin: none; */
    bottom: 0%;
  
    background: linear-gradient(28.12deg, #0B2255 4.71%, rgba(11, 34, 85, 0) 30.32%);
} 


.blur-ell1 {
/* Ellipse 1 */

box-sizing: border-box;

position: absolute;
width: 52.9167%;
height: 63.33%;
left: -15.198%;
top:60.22%;

background: var(--bgMedium);
filter: blur(114px);
}
.manu-blur{
    width: 100% !important;
    left: 0% !important;
}
.blur-ell3 {
/* Ellipse 3 */

box-sizing: border-box;

position: absolute;
width: 28.75%;
height: 42.87%;
left: -12.865%;
top: 67.22%;

background: var(--bgMedium);
filter: blur(114px);
}

.blur-ell4 {
/* Ellipse 4 */

box-sizing: border-box;

position: absolute;
width: 34.53%;
height: 51.48%;
left: -9.22%;
top: 30.55%;

background: var(--bgMedium);
filter: blur(114px);
}

.ec-head {
    /* Edge city */

    position: absolute;
    width: 31.51%;
    /* height: 29.63%; */
    left: 3.667%;
    bottom: 10.3%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 300;
    font-size: 10.208vw;
    line-height: 18svh;

    /* or 82% */
    letter-spacing: -0.06em;
    text-transform: uppercase;

    color: var(--fontWhite);
}

.nav-button-ec {
    
    position: absolute;
    /* width: 9%; */
    height: 4%;
    left: 4.167%;
    top: 55.2%;
    z-index: 1;

    background: var(--bgLight);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);

    padding: 7px 14px;
    border: none;

    border-radius: 40px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color:var(--fontWhite);
    opacity: 0.8;
    
}

.dc-sub {
    /* Technology is powering transformation in urban environment around the world */

    position: absolute;
    width: 19.74%;
    /* height: 48px; */
    left: 4.8%;
    top: 93.63%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 2.222svh;

    /* or 150% */

    color: var(--fontWhite);
}

.ec-sub {
    /* Technology is powering transformation in urban environment around the world */

    position: absolute;
    width: 19.74%;
    /* height: 48px; */
    left: 4.8%;
    top: 90.63%;

    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 2.222svh;

    /* or 150% */

    color: var(--fontWhite);
}

.dc-head {
    position: absolute;
    width: 31.51%;
    height: 23.7%;
    left: 4.167%;
    top: 60.185%;


    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 300;
    font-size: 8.542vw;
    line-height: 16svh;

    /* or 78% */
    letter-spacing: -0.06em;
    text-transform: uppercase;

    color: var(--fontWhite);
}
@media screen and (max-height: 550px) {
.dc-head {
    font-size: 5.542vw;
    width: 20%;
}
}
@media screen and (max-height: 450px) {
.dc-sub {
    display: none;
}
}
#lock{ display:none;z-index: 0; }
@media screen and (orientation: portrait) {
#lock{ display:block; }
.wrapper{ display:none; }
}
@media screen and (orientation: landscape) {
#lock{ display:none; z-index: 0;}
.wrapper{ display:block; }
}

/* for sub top=head+height+8px */
/* .toolbar {
    position: fixed;
    bottom: 0;
    right: 0%;
    width: 60%;
    height: 10%;
    display: flex;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: transparent;
} */
.urban-toolbar{
    bottom: 4.5% !important;
    width: 65% !important;
    height: 10% !important;
}
.bg-front {
    position: fixed;
    top: 0;
    left:0;
    /* height: 100%; */
    width: 100%;
    /* filter: brightness(50%); */
    margin: 0;
    overflow: hidden;
    /* object-fit: cover; */
    /* pointer-events: none; */
    z-index: 0;
    /* filter: brightness(50%); */
  }

.toolbar-button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    margin: 1%;
    border: none;
    width: 12%;
    font-size: max(10px, 1.042vw);
    /* border-radius: 10px; */
    font-family: var(--fontFamily1);
    color:var(--fontWhite);
    font-weight: 500;
    opacity: 0.8;
    cursor: pointer;

    background: var(--bgWhite);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    

    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;
}

.toolbar-button.selected {
    background: var(--bgDark);
    box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */
    opacity: 1;
    border-radius: 12px;
}

.toolbar-button.selected::before {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(25, 74, 179, 0.85);
}

.triangle {
    position: relative;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #007bff;
    margin-top: -2px;
}

/* #btnGuidedTour {
    font-weight: bold;
    /* background: color-mix(in srgb, var(--bgBottomToolbar) 90%, black); */
    /* background: radial-gradient(
        to bottom, 
        var(--bgBottomToolbar), 
        color-mix(in srgb, var(--bgBottomToolbar) 85%, black) 80%, 
        var(--bgBottomToolbar)
        ); */
        /* background: linear-gradient(
            to bottom, 
            var(--bgBottomToolbar) 0%,
            var(--bgBottomToolbar) 10%, 
            color-mix(in srgb, var(--bgBottomToolbar) 90%, black) 50%, 
            var(--bgBottomToolbar) 90%, 
            var(--bgBottomToolbar) 100%
          ); */
    /* background: content-box radial-gradient(#a9a9a9, var(--bgBottomToolbar)); */
    /* text-shadow: 2px 2px 4px #a9a9a9; */
    /* box-shadow: inset 1px 1px gold*/
    /* border: 1px solid rgba(255, 255, 255, .6); */
    /* border: ; */
/* } */

.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: var(--bgWorldBackground); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;

}
.loading__container {

	width: 200px;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100000;
}
.loaderContainer{
	position: absolute;
	display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    display: -moz-flex;
    -moz-flex-direction: row;
    -moz-justify-content: center;
    -moz-align-items: center;
    display: -ms-flex;
    -ms-flex-direction: row;
    -ms-justify-content: center;
    -ms-align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
    transition: transform 0.5s ease;
    transform: scale(1) !important;

}


.loader {
	position: relative;
	width: 100px;
	height: 100px;
	top: 15%;
    left: -27%;
}

.loader:before,
.loader:after {
	content: '';
	border-radius: 50%;
	position: absolute;
	inset: 0;
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.loader:after {
	box-shadow: 0 2px 0 var(--fontWhite) inset;
	animation: rotate 2s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.close-btn-container {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 100;
}

.close-btn-container .close-btn {
	width: 2rem;
	height: 2rem;
	font-size: 1.5rem;
	cursor: pointer;
}



.loadingTitle{
position: absolute;
top: 75%;
font-family: var(--fontFamily1);
font-style: normal;
font-weight: 500;
font-size: 1.4vw;
line-height: 4vh;
}
.titleTwo{
    font-family: var(--fontFamily1);
    font-style: normal;
    font-weight: 500;
    font-size: 0.8vw;
    
}

.progressbar{
    position: relative;
	width: 100px;
	height: 100px;
	top: 15%;
    /* left: -27%; */
}
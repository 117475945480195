:root {
    --fontWhite: #ffffff; 
    --fontToolbar: #80C8FA; 
    --fontLight: #E5F8FF; 
    --fontBlack: #000000; 

    --bgWhite: #ffffff; 
    --bgGrey: #D9D9D9; 
    --bgSeparator: #3072CB; 
    --bgSeparator1: #F0F6FA; 
    /*     --bgSeparator1: #C9E7FF; */ 
    --bgShadow: #000000; 
    --bgMedium: #0C2055;
    --bgDark: #1033A4;
    --bgLight: #3072CB;

    --fontFamily1:'Roboto';
    --fontFamilyRobotoMedium:'Roboto';
    --fontFamilyRobotoBold:'Roboto';
    
    --fontWeightRegular:150;
    --fontWeightMedium:200;
    --fontWeightBold:400;
    --contentLineHeight: 1.3;

    --opacityOpaque:1;
    --opacityHigh:0.95;
    --opacityLow:0.9;
    --opacityVeryLow:0.4;

    --bgWorldBackground:var(--bgSeparator);
    --bgWorldBackground1:var(--bgSeparator1);

    --bagWelcomeDotCircleFill:var(--bgDark);
    --bagWelcomeDotCircle:var(--bgWhite);
    --bagWelcomeDotCircleStroke:var(--bgSeparator);
    --bgNextPreButton:var(--bgMedium);
    --bgNextPreButtonHover:var(--bgDark);

    --bgBottomToolbar: var(--bgMedium);
    --BottomToolbarOpacity: var(--opacityHigh);
    --BottomToolbarFontFamily: var(--fontFamilyRobotoMedium);
    --BottomToolbarFontWeight: var(--fontWeightMedium);
/*    --BottomToolbarFontSize: calc(0.469vw + 0.834svh); */
    --BottomToolbarFontSize: calc(0.42vw + 0.74svh);
    --BottomToolbarFontColor: var(--fontWhite);
    --bgBottomToolbarHighlightedButton: var(--bgDark);
    --BottomToolbarSeparator: var(--bgSeparator);

    --bgTopNavbar: var(--bgMedium);
    --TopNavbarOpacity: var(--opacityHigh);

    --bgCards: var(--bgMedium);
    --CardsOpacity: var(--opacityLow);
    --CardsFontFamily: var(--fontFamilyRobotoMedium);
    --CardsFontWeight: var(--fontWeightMedium);
    --CardsFontSize: calc(0.670vw + 1.15svh);
    --CardsFontColor: var(--fontWhite);
    
    --CardsContentFontFamily: var(--fontFamily1);
    --CardsContentFontWeight: var(--fontWeightRegular);
    --CardsContentFontSize: calc(0.42vw + 0.74svh);
    --CardsContentLineHeight: var(--contentLineHeight);

		--DataCardCTAFontSize: 1vw;

    --bgAchievedThroughButton: var(--bgDark);
    --AchievedThroughButtonOpacity: var(--opacityHigh);
    --AchievedThroughButtonFontFamily: var(--fontFamily1);
    --AchievedThroughButtonFontWeight: var(--fontWeightBold);
    --AchievedThroughButtonFontSize: calc(0.4vw + 0.71svh);
    --AchievedThroughButtonFontColor: var(--fontLight);

    --bgCarousel: var(--bgMedium);
    --CarouselOpacity: var(--opacityLow);
    --CarouselFontFamily: var(--fontFamilyRobotoMedium);
    --CarouselFontWeight: var(--fontWeightMedium);
    --CarouselFontSize: calc(0.677vw + 1.2svh);
    --CarouselFontColor: var(--fontWhite);

    --CarouselContentFontFamily: var(--fontFamily1);
    --CarouselContentFontWeight: var(--fontWeightRegular);
    --CarouselContentFontStyle: var(--fontStyle);
    --CarouselContentFontSize: calc(0.469vw + 0.834svh);
    --CarouselContentLineHeight: var(--contentLineHeight);

    --bgHamburgerMenuList: var(--bgMedium);
    --HamburgerMenuListOpacity: var(--opacityLow);
    --HamburgerMenuListFontFamily: var(--fontFamily1);
    --HamburgerMenuListFontWeight: var(--fontWeightRegular);
    --HamburgerMenuListFontSize: calc(0.4165vw + 0.74svh);
    --HamburgerMenuListFontColor: var(--fontWhite);

    --bgHamburgerMenuListHover: var(--bgDark);

    --bgHotspotHover: var(--bgMedium);
    --HotspotHoverOpacity: var(--opacityLow);
    --HotspotHoverFontFamily: var(--fontFamilyRobotoMedium);
    --HotspotHoverFontWeight: var(--fontWeightMedium);
    --HotspotHoverLabelFontSize: calc(0.521vw + 0.926svh);
    --HotspotHoverFontSize: calc(0.4vw + 0.71svh);
    --HotspotHoverFontColor: var(--fontWhite);

    --bgOnboardingCard: var(--bgWhite);
    --bgOnboardingCardImg: var(--bgGrey);
    --OnboardingCardOpacity: var(--opacityOpaque);
    --OnboardingCardFontFamily: var(--fontFamilyRobotoMedium);
    --OnboardingCardFontWeight: var(--fontWeightMedium);
    --OnboardingCardFontSize: calc(1vw + 0.834svh);
    --OnboardingCardFontColor: var(--bgMedium);

    --OnboardingCardContentFontFamily: var(--fontFamily1);
    --OnboardingCardContentFontWeight: var(--fontWeightRegular);
    --OnboardingCardContentFontSize: calc(0.65vw + 0.648svh);
    --OnboardingCardContentFontColor: var(--bgMedium);
    --OnboardingCardNumberingFontColor: var(--bgLight);
    --bgOnboardingCardHighlightedButton: var(--bgLight);
    --OnboardingCardContentLineHeight: var(--contentLineHeight);

    --bgUcStory: var(--bgMedium);
    --bgUcStoryNextPrevButton: var(--bgDark);
		--bgUcStoryHighlight: var(--bgShadow);
    --UcStoryOpacity: var(--opacityHigh);
    --UcStoryFontFamily: var(--fontFamilyRobotoMedium);
    --UcStoryFontWeight: var(--fontWeightMedium);
    --UcStoryFontSize: calc(0.8335vw + 1.481svh);
    --UcStoryFontColor: var(--fontWhite);

    --bgUcStoryBlocks: var(--bgDark);
    --UcStoryBlocksFontFamily: var(--fontFamilyRobotoBold);
    --UcStoryBlocksFontWeight: var(--fontWeightBold);
/*    --UcStoryBlocksFontSize: calc(0.469vw + 0.834svh); */
    --UcStoryBlocksFontSize: calc(0.6vw + 1svh);

    --UcStoryBlocksContentFontFamily: var(--fontFamily1);
    --UcStoryBlocksContentFontWeight: var(--fontWeightRegular);
/*    --UcStoryBlocksContentFontSize: calc(0.4165vw + 0.74svh); */
    --UcStoryBlocksContentFontSize: calc(0.5vw + 0.8svh);
    --UcStoryBlocksContentLineHeight: var(--contentLineHeight);
    --UcStoryBlocksContentFontColor: var(--fontWhite);

    --bgSpinner: var(--bgLight);

    }
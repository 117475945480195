.toolbar {
    position: fixed;
    bottom: 0;
    right: 0%;
    width: 70%;
    height: 11%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: transparent;
    z-index: 3;
}
.iocplayer {
	z-index: 999999;
	position: absolute;
	top: 50%;
	left:50%;
	width: 50%;
	height: auto;
	transform: translate(-50%,-50%);
}
.reset-toolbar{
    left:1%;
    width:10%;
    height:10%;
    margin-bottom:0.5%;
}
.plain-toolbar{
    position: fixed;
    bottom: 0;
    right: 0%;
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: var(--bgBottomToolbar);
    z-index: 3;
    padding-right: 2%;
}

.plain-reset-toolbar{
    z-index: 4;
    position: fixed;
    bottom: 0;
    height: 7%;
    display: flex;
    align-items: center;
    width: 20%;
    margin: 0;
    justify-content: start;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0% 1%;
    /* width: 100%; */
}
/* .plain-reset-toolbar:hover{
    width: 100%;
}  */
.plain-divider{
    position: relative;
    width: 0.15%;
    height: 50%;
    z-index: 3;
    /* margin: 0px 0.3%; */
    background: var(--BottomToolbarSeparator);
}

.plain-reset-divider{
    position: relative;
    width: 0.70%;
    height: 50%;
    z-index: 5;
    /* margin: 0px 0.3%; */
    background: var(--BottomToolbarSeparator);
}
.bg-front {
    position: fixed;
    top: 0;
    left:0;
    /* height: 100%; */
    width: 100%;
    /* filter: brightness(50%); */
    margin: 0;
    overflow: hidden;
    /* object-fit: cover; */
    /* pointer-events: none; */
    z-index: 0;
    /* filter: brightness(50%); */
  }

.toolbar-button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1% 3%;
    margin: 1% 0.4%;
    border: none;
    width: 12%;
    font-size: var(--BottomToolbarFontSize);
    font-family: var(--BottomToolbarFontFamily);
    color:var(--BottomToolbarFontColor);
    /* font-weight: var(--BottomToolbarFontWeight); */
    opacity: var(--BottomToolbarOpacity);
    cursor: pointer;

    background: var(--bgBottomToolbar);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    

    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;
}
.plain-toolbar-button{
    background: transparent;
    font-size: max(10px, var(--BottomToolbarFontSize));
    font-family: var(--BottomToolbarFontFamily);
    color:var(--BottomToolbarFontColor);
    /* font-weight: var(--BottomToolbarFontWeight); */
    border: none;
    cursor: pointer;
    padding: 1%;
    margin: 0px 0.3%;
    /* font-size: 16px; */
    /* margin: 0px 0.3%; */
    /* padding: 0.5%; */
}
.plain-reset-toolbar-button{
    background: transparent;
    font-size: max(10px, var(--BottomToolbarFontSize));
    font-family: var(--BottomToolbarFontFamily);
    color:var(--BottomToolbarFontColor);
    /* font-weight: var(--BottomToolbarFontWeight); */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5%;
    margin: 0px 0.75%;
    /* font-size: 16px; */
    /* margin: 0px 0.3%; */
    /* padding: 0.5%; */
}
.plain-reset-toolbar .plain-toolbar-button {
padding: 17px;
margin: 0px;
display: flex;
align-items: center;
gap: 5%;
}
.plain-toolbar-button:hover{
    color: var(--fontWhite);
    /* background: var(--bgDark); */
    /* margin: 0px 0.3%;
    padding: 1%; */
    border-radius: 8px;
}
.plain-button-selected{
    color: var(--fontWhite);
    background: var(--bgBottomToolbarHighlightedButton);
    /* padding: 1%; */
    /* margin: 0px 0.3%; */
    border-radius: 8px;
}
.toolbar-button.selected {
    background: var(--bgBottomToolbarHighlightedButton);
    box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;
}

.toolbar-button.selected::before {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(25, 74, 179, 0.85);;
}

.triangle {
    position: relative;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid var(--bgBottomToolbarHighlightedButton);
    margin-top: -2px;
}
.hot-spot-subMenu{
	width: 15%;
	/* height: 13%; */
	z-index: 9;
	cursor: pointer;
	position: absolute;
	background-color: var(--bgHotspotHover);
	opacity: var(--HotspotHoverOpacity);
	border-radius: 8px;
	padding: 1%;
	box-shadow: -1vw 0.75vw 1.75vw -0.625vw var(--bgShadow);
	transform: translate(calc(-3% - 15px), calc(-100% - 14px));
	transform-origin: top left;
	animation: animateGrow ease-in .3s;
}
	
.animationHover-exit {
	opacity: 1;
	transform: translate(calc(-3% - 15px), calc(-100% - 14px));
	transform-origin: top left;
}

.animationHover-exit-active {
	transform: translate(calc(-3% - 15px), calc(-100% - 14px));
	transform-origin: top left;
	animation: animateExit ease-in 0.35s;
}

.hover-label-text{
    color: var(--HotspotHoverFontColor);
    font-family: var(--HotspotHoverFontFamily);
    font-weight: var(--HotspotHoverFontWeight);
    font-size: var(--HotspotHoverLabelFontSize);
    text-align: left;
    animation: fadeIn ease 0.2s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity: var(--HotspotHoverOpacity);}
}

@keyframes animateGrow {
    0% {opacity:0; scale:0%;};
    25% {opacity: var(--HotspotHoverOpacity); scale:0%}
    100% {opacity: var(--HotspotHoverOpacity); scale:100%}
}

@keyframes animateGrow2 {
    0% {opacity:0; translate:calc(-3% - 15px), calc(-100% - 14px); scale:0%;};
    25% {opacity: var(--HotspotHoverOpacity); translate:calc(-3% - 15px), calc(-100% - 14px); scale:0%}
    100% {opacity: var(--HotspotHoverOpacity); translate:calc(-3% - 15px), calc(-100% - 14px); scale:100%}
}

@keyframes animateExit {
	0% {opacity: var(--HotspotHoverOpacity); scale:100%}
	75% {opacity: var(--HotspotHoverOpacity); scale:0%}
	100% {opacity:0; scale:0%;};
}

.hot-spot-Hover{
    /* width: 286px; */
    /* height: 124px; */
    z-index: 9;
    cursor: pointer;
    position: absolute;
    background-color: var(--bgMedium);
    opacity: 0.8;
    padding: 5%;
    color: var(--fontWhite);
    font-family: var(--fontFamily1);
    font-size: calc(0.6vw + 1.06svh);
    border-radius: 8px;
}
.button-group{
    padding-left: 5%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}
.zoom-in{
    font-size: var(--HotspotHoverFontSize);
    font-weight: var(--HotspotHoverFontWeight);
    font-family: var(--HotspotHoverFontFamily);
    animation: fadeIn ease 0.3s;
}

.hot-spot-subMenu::before {
    content: '';
   position: absolute;
    bottom: -14px;
    left: 3%;
    /* transform: translateX(-455%); */
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
   border-top: 15px solid var(--bgHotspotHover);
	 ;
    /* border-top: 1% solid; */
}
.learn-more{
    background: var(--bgDark);
    border-radius: 6px;
    font-size: var(--HotspotHoverFontSize);
    font-weight: var(--HotspotHoverFontWeight);
    font-family: var(--HotspotHoverFontFamily);
    padding: 3% 3%;
    animation: fadeIn ease 0.3s;
  /*  width: 30%;*/
}

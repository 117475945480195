.nav-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
		background-image: linear-gradient(to top, var(--bgWorldBackground) , var(--bgWorldBackground1));
}



.navbar {
    position: absolute;
    width: 100%;
    height: 6.67%;
    left: 0;
    top: 0;

    background: var(--bgTopNavbar);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(2px);
    z-index: 3;
    opacity: var(--TopNavbarOpacity);
}

.dell {
    position: absolute;
    width: 9.4%;
    left: 5.4167%;
    top: 2.3426%;
    z-index: 3;
}

.intel {
    position: absolute;
    width: 2.64%;

    aspect-ratio: 2.4375;
    left: 16.25%;
    top: 2.2222%;
    z-index: 3;
}

.divider1 {
    position: absolute;
    width: 0.0677%;
    height: 1.5833%;
    left: 15.505%;
    top: 2.50%;
    z-index: 3;
    background: var(--bgWhite);
}

.divider2 {
    position: absolute;
    width: 0.0677%;
    height: 1.5833%;
    left: 89.65%;
    top: 2.50%;
    z-index: 3;
    background: var(--bgWhite);
}
.divider3 {
    position: absolute;
    width: 0.0677%;
    height: 1.5833%;
    left: 83.21%;
    top: 2.50%;
    z-index: 3;
    background: var(--bgWhite);
}

.mute {
    position: absolute;
    left: 86.5875%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}

.playPause {
    position: absolute;
    left: 84.7175%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}


.twt {
    position: absolute;
    left: 91.15%;
    right: 7.29%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}

.lin {
    
    position: absolute;
    left: 93.02%;
    right: 5.42%;
    top: 1.85%;
    
    width: 1.5625%;
    aspect-ratio: 1;
}

.withclick {
    z-index: 4;
    cursor: pointer;
}
